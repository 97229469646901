import React, { useEffect } from 'react'

import { ChakraProvider } from '@chakra-ui/react'
import { Inter } from '@next/font/google'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import Router from 'next/router'

import Layout from 'components/Layout'
import * as gtag from 'utils/gtag'

import theme from '../theme'

const inter = Inter({ subsets: ['latin'] })

const App = ({ Component, pageProps }) => {
	useEffect(() => {
		const handleRouteChange = (url) => {
			gtag.pageview(url)
		}

		Router.events.on('routeChangeComplete', handleRouteChange)

		return () => {
			Router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [])

	return (
		<main className={inter.className}>
			<Head>
				<title>Atty</title>
			</Head>
			<DefaultSeo
				openGraph={{
					type: 'website',
					locale: 'en_AU',
					url: 'https://atty.shop/',
					site_name: 'Atty',
				}}
			/>
			<ChakraProvider theme={theme}>
				<Layout>
					<Component {...pageProps} />
				</Layout>
			</ChakraProvider>
		</main>
	)
}

export default App
