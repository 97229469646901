import React from 'react'

import { IconButton, useColorMode } from '@chakra-ui/react'

import { Icon } from 'components/Icon'

export default function ColorToggle(props) {
	const { colorMode, toggleColorMode } = useColorMode()

	return (
		<IconButton
			variant="ghost"
			aria-label="Toggle dark mode"
			title="Toggle dark mode"
			onClick={() => toggleColorMode()}
			{...props}
			fontSize="2xl"
		>
			<Icon
				color={colorMode === 'light' ? 'brand.900' : 'brand.100'}
				icon={['fad', colorMode === 'light' ? 'sun' : 'moon']}
			/>
		</IconButton>
	)
}
