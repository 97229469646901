import React from 'react'

import { Link } from '@chakra-ui/react'
import RouterLink from 'next/link'

const WrappedLink = (props) => (
	<Link as={RouterLink} {...props} />
)

export default WrappedLink
