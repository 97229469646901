import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { config, library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

config.autoAddCss = false

library.add(fad, fab)

const buttons = {
	base: {
		borderRadius: 999,
		whiteSpace: 'nowrap',
		px: { base: 5, md: 6 },
		py: { base: 2, md: 3 },
		h: 'auto',
		gap: { base: 3, md: 12 },
		'.chakra-icon': {
			mr: { base: 0, md: -1 }
		}
	},
	solid: {
		bg: 'brand.100',
		color: 'white',
		_hover: {
			bg: 'brand.900'
		}
	},
	outline: {
		color: 'brand.100',
		borderColor: 'brand.100',
		borderWidth: '3px',
		borderStyle: 'dashed',
		_hover: {
			bg: 'tranparent',
			color: 'brand.900',
			borderColor: 'brand.900'
		}
	}
}

const base = {
	colors: {
		brand: {
			100: '#0084FF',
			900: '#FFB600',
		},
		blue: {
			brand: '#0084FF',
			brandAlpha: '#0084ff1f',
			50: '#dbf4ff',
			100: '#addbff',
			200: '#7cc2ff',
			300: '#4aa9ff',
			400: '#1a90ff',
			500: '#0077e6',
			600: '#005cb4',
			700: '#004282',
			800: '#002851',
			900: '#000e21',
		},
		yellow: {
			brand: '#FFB600',
			50: '#fff8da',
			100: '#ffe9ad',
			200: '#ffdb7d',
			300: '#ffcc4b',
			400: '#ffbd1a',
			500: '#e6a400',
			600: '#b37f00',
			700: '#805b00',
			800: '#4e3700',
			900: '#1e1100',
		},
		green: {
			brand: '#00B111',
			50: '#deffe3',
			100: '#b0ffb7',
			200: '#7fff8b',
			300: '#4dff5e',
			400: '#20ff32',
			500: '#0be619',
			600: '#00b311',
			700: '#00800a',
			800: '#004d03',
			900: '#001b00',
		},
		pink: {
			brand: '#FF00B6',
			50: '#ffe2fd',
			100: '#ffb1eb',
			200: '#ff7fdb',
			300: '#ff4ccc',
			400: '#ff1abd',
			500: '#e600a4',
			600: '#b4007f',
			700: '#81005c',
			800: '#500038',
			900: '#1f0015',
		},
		input: '#E7EFFF',
		error: '#F70000'
	},
	components: {
		Alert: {
			baseStyle: {
				container: {
					borderRadius: 999,
				}
			}
		},
		Button: {
			variants: {
				solid: {
					...buttons.base,
					...buttons.solid
				},
				icon: {
					...buttons.base,
					...buttons.solid,
					h: undefined,
					px: undefined,
					py: undefined,
					'.chakra-icon': {
						mr: 0
					}
				},
				outline: {
					...buttons.base,
					...buttons.outline
				}
			}
		},
		Form: {
			baseStyle: {
				helperText: {
					mt: -1,
					mb: 4
				}
			}
		},
		Heading: {
			baseStyle: {
				letterSpacing: 'tighter',
				fontWeight: 'semibold',
			},
			sizes: {
				'h1': {
					fontSize: { base: 56, md: 66 },
					lineHeight: 1.2,
					fontWeight: 'bold',
				},
				'h2': {
					fontSize: { base: 40, md: 50 },
					lineHeight: 1.2,
					fontWeight: '700',
				},
				'h3': {
					fontSize: { base: 24, md: 34 },
					lineHeight: 1.2,
					fontWeight: '700',
					pt: 10
				},
			}
		},
		Input: {
			variants: {
				outline: (props) => ({
					field: {
						borderRadius: '7px',
						borderWidth: 0,
						':active,:focus': {
							boxShadow: 'none',
							outline: 'none'
						},
						px: 5,
						h: 14,
						color: mode('inherit', 'whiteAlpha.900')(props),
						bg: mode('#E7EFFF !important', 'gray.900')(props),
						_invalid: {
							bg: mode('#ffe7e7 !important', '#311010 !important')(props),
							boxShadow: 'none'
						}
					},
					addon: {
						borderRadius: '7px',
						borderWidth: 0,
						color: mode('inherit', 'whiteAlpha.900')(props),
						bg: mode('#E7EFFF !important', 'gray.900')(props),
						px: 5,
						h: 14,
						_invalid: {
							bg: '#ffe7e7 !important',
							boxShadow: 'none'
						}
					}
				})
			}
		},
		Link: {
			baseStyle: {
				color: 'brand.100',
				fontWeight: '600',
				_hover: {
					color: 'brand.900',
					textDecoration: 'none'
				},
				_focus: {
					boxShadow: 'none'
				}
			},
			variants: {
				button: {
					...buttons.base,
					...buttons.solid,
					display: 'inline-block',
					color: '#ffffff !important',
				}
			}
		},
		Select: {
			variants: {
				outline: (props) => ({
					field: {
						borderRadius: '7px',
						borderWidth: 0,
						bg: mode('input', 'gray.900')(props),
						px: 5,
						h: 14,
						':active,:focus': {
							boxShadow: 'none',
							outline: 'none'
						},
						_invalid: {
							bg: mode('#ffe7e7 !important', '#311010 !important')(props),
							boxShadow: 'none'
						}
					}
				})
			}
		},
		Textarea: {
			variants: {
				outline: (props) => ({
					minHeight: 300,
					borderRadius: '7px',
					borderWidth: 0,
					color: mode('inherit', 'whiteAlpha.900')(props),
					bg: mode('#E7EFFF !important', 'gray.900')(props),
					p: 5,
					h: 14,
					':active,:focus': {
						boxShadow: 'none',
						outline: 'none'
					},
					_invalid: {
						bg: '#ffe7e7',
						boxShadow: 'none'
					}
				})
			}
		},
	},
	fonts: {
		heading: null,
		body: null,
	},
	layerStyles: {
		trio: {
			display: 'grid',
			gridTemplateColumns: '[full-start] minmax(0,1fr) [main-start] repeat(6,[col-start] minmax(10px,75px)) [main-end] minmax(0,1fr) [full-end]',
			gridColumnGap: [10, 50],
			'.main': { gridColumn: 'col-start 1/span 6' },
		},
		section: {
			px: { base: 6, lg: 0 },
			py: { base: 6, lg: 8 },
			position: 'relative',
			':before': {
				content: '""',
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: { base: 0, lg: -7, xl: -10 },
				left: { base: 0, lg: -7, xl: -10 },
				borderColor: 'blue.brandAlpha',
				borderWidth: 5,
				borderRadius: '1rem',
				zIndex: -1
			}
		}
	},
	styles: {
		global: {
			body: {
				fontSize: 'lg',
				letterSpacing: 'tight',
				lineHeight: 'tall',
			}
		}
	}
}

export default extendTheme(base)

// export default {
//   colors: {
//     text: "#1A1A1A",
//     background: "#fff",
//     primary: "#0084FF",
//     secondary: "#FFB600",
//     blue0: "#0077FF",
//     blue1: "#99CEFF",
//     yellow0: "#FFB600",
//     pink0: "#FF00B6",
//     green0: "#00D630",
//     green1: "#99EFAC",
//     modes: {
//       dark: {
//         text: "#fff",
//         background: "#222",
//         primary: "#0084FF",
//         secondary: "#FFB600",
//       },
//     },
//   },
//   fontSizes: [16, 18, 22, 26, 30, 34, 42, 50, 58, 66],
//   space: [0, 10, 20, 30, 40, 60, 120],
//   styles: {
//     root: {
//       fontSize: [1, 2],
//       letterSpacing: "-0.025em",
//     },
//     h1: {
//       fontSize: [7, 8, 9],
//       letterSpacing: "-0.05em",
//       lineHeight: 1.2,
//       mt: [5],
//       mb: [3],
//     },
//     h2: {
//       fontSize: [5, 6, 7],
//       lineHeight: 1.2,
//       mt: [5],
//       mb: [3],
//     },
//     h3: {
//       fontSize: [3, 4, 5],
//       lineHeight: 1.2,
//       mt: [5],
//       mb: [3],
//     },
//     a: {
//       color: "primary",
//       textDecoration: "none",
//       fontWeight: 400,
//       ":hover": {
//         color: "secondary",
//       },
//     },
//     p: {
//       fontSize: [1, 2],
//       lineHeight: 1.8,
//     },
//   },
// };
