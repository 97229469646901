import React from 'react'

import { Link } from '@chakra-ui/react'

const Start = () => {
	if (process.env.NEXT_PUBLIC_DOMAIN === 'attyshop.xyz') {
		return (
			<Link
				href={`https://admin.${process.env.NEXT_PUBLIC_DOMAIN}/new-account`}
				variant="button"
				sx={{
					my: 2,
					fontSize: 'xl'
				}}
			>
				Get Started
			</Link>
		)
	}

	return (
		<Link
			href='#'
			variant="button"
			sx={{
				my: 2,
				fontSize: 'xl'
			}}
		>
			Coming Soon
		</Link>
	)
}

export default Start
