import React, { useEffect, useRef } from 'react'

import { Flex, Box, IconButton, useDisclosure, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Spacer, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

import ColorToggle from 'components/ColorToggle'
import Logo from 'components/Logo'
import Menu from 'components/Menu'

import { Icon } from './Icon'

const mobileMenuBreakpoint = 'md'

const Header = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef()
	const router = useRouter()

	useEffect(() => {
		const handleRouteChange = () => {
			onClose()
		}

		router.events.on('routeChangeStart', handleRouteChange)

		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box as="header" p={{ base: 4, md: 20 }}>
				<Box
					sx={{
						position: 'absolute',
						top: { base: 4, md: 10 },
						left: { base: -8, md: -16, lg: -100 },
						img: {
							width: { base: 100, md: 150, lg: 255 },
						}
					}}
				>
					<Logo />
				</Box>
				<Box
					layerStyle="trio"
					sx={{
						display: ['none', 'grid'],
						fontSize: 'lg',
						alignItems: 'center',
					}}
				>
					{/* desktop menu */}
					<HStack
						className="main"
						as="nav"
						sx={{
							display: { base: 'none', [mobileMenuBreakpoint]: 'flex' },
							mx: { base: 0, [mobileMenuBreakpoint]: -4 },
							gap: { base: 4, lg: 8 }
						}}
					>
						<Menu />
					</HStack>
					<Box
						sx={{
							display: { base: 'none', [mobileMenuBreakpoint]: 'block' },
							textAlign: 'right'
						}}
					>
						<ColorToggle />
					</Box>
				</Box>

				{/* mobile menu */}
				<Flex
					sx={{
						p: 3,
						display: { base: 'flex', [mobileMenuBreakpoint]: 'none' },
						justifyContent: 'flex-end',
						alignItems: 'center',
					}}
				>
					<ColorToggle sx={{ zIndex: 20, mx: 2 }} />
					<IconButton
						variant="ghost"
						aria-label="Menu"
						icon={<Icon icon={['fad', 'bars']} color="brand.100" />}
						onClick={onOpen}
						ref={btnRef}
						isRound
						display={{ base: 'flex', lg: 'none' }}
					/>
				</Flex>
			</Box>

			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				finalFocusRef={btnRef}
				blockScrollOnMount={false}
				size="full"
				isFullHeight
			>
				<DrawerOverlay>
					<DrawerContent>
						<DrawerHeader
							px={4}
							py={6}
						>
							<HStack>
								<Box
									sx={{
										pos: 'absolute',
										top: { base: 4, md: 10 },
										left: { base: -8, md: -16, lg: -100 },
										img: {
											width: { base: 100, md: 150, lg: 255 },
										}
									}}
								>
									<Logo />
								</Box>
								<Spacer />
								<ColorToggle />
								<IconButton
									variant="icon"
									aria-label="Close"
									onClick={onClose}
									ref={btnRef}
									icon={<Icon icon={['fad', 'xmark']} />}
									isRound
								/>
							</HStack>
						</DrawerHeader>
						<DrawerBody>
							<VStack alignItems="start" gap={4} my={8} sx={{ '> *': { mt: 0 }}}>
								<Menu />
							</VStack>
						</DrawerBody>
					</DrawerContent>
				</DrawerOverlay>
			</Drawer>

		</>
	)
}

export default Header
