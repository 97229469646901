import React from 'react'

import { Icon as BaseIcon, IconProps, keyframes } from '@chakra-ui/react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

const spin = keyframes({
	'100%': {
		transform: 'rotate(360deg)',
	}
})

export function Icon({ ...args }: FontAwesomeIconProps & IconProps) {
	return (
		<BaseIcon
			as={FontAwesomeIcon}
			__css={{
				'.fa-secondary': {
					opacity: 0.3
				},
				'&.fa-spin': {
					animation: `${spin} 0.6s linear infinite`
				}
			}}
			{...args}
		/>
	)
}
