import React from 'react'

import { Flex, Box, Heading, SimpleGrid, VStack, Text } from '@chakra-ui/react'

import ColorToggle from 'components/ColorToggle'
import Start from 'components/Start'

import Link from './Link'

const Footer = () => {
	return (
		<Box as="footer">
			<Flex
				sx={{
					flexDirection: ['column', 'row'],
					alignItems: ['auto', 'center'],
					my: [50, 150],
				}}
			>
				<Box
					sx={{
						height: [100, 300],
						mb: [4, 0],
						flex: ['auto', 1],
						backgroundImage: 'url(/logo-trio.svg)',
						backgroundPositionX: 'right',
						backgroundPositionY: 'center',
						backgroundRepeat: 'repeat-x',
						backgroundSize: [250, 'auto'],
					}}
				/>
				<Flex
					sx={{
						mx: [10, 150],
						flexDirection: ['column', null, null, null, 'row'],
						justifyContent: 'space-evenly',
						'> *': {
							w: 200,
							my: { base: 8, xl: 0 },
							gap: 2,
							h3: {
								fontSize: 24
							}
						}
					}}
				>
					<VStack alignItems="flex-start">
						<Heading as="h3">Hello</Heading>
						<Link href="/">
							Home
						</Link>
						<Link href="/pricing">
							Pricing
						</Link>
					</VStack>
					<VStack alignItems="flex-start">
						<Heading as="h3">About</Heading>
						<Link href="/about">
							About
						</Link>
						<Link href="/blog">
							Blog
						</Link>
					</VStack>
					<VStack alignItems="flex-start">
						<Heading as="h3">Help</Heading>
						<Link href="/contact">
							Contact
						</Link>
					</VStack>
					<VStack alignItems="flex-start">
						<Heading as="h3">Legal</Heading>
						<Link href="/terms">
							Terms
						</Link>
						<Link href="/privacy">
							Privacy Policy
						</Link>
						<Text pt={4}>© Atty Pty Ltd</Text>
					</VStack>
					<VStack
						mt={{ base: 10, md: -2 }}
						alignItems='flex-start'
					>
						<Start />
					</VStack>
				</Flex>
			</Flex>
			<SimpleGrid
				columns={[1, 3]}
				gap={10}
				alignItems="center"
				textAlign={{ base: 'center', sm: 'left' }}
				p={10}
			>
				<Box sx={{ strong: { px: '4px' } }}>
          			Supporting small business on their journey to success.
				</Box>
				<Box sx={{ textAlign: ['center'] }}>
					<ColorToggle />
				</Box>
			</SimpleGrid>
		</Box>
	)
}

export default Footer
