import React from 'react'

import Image from 'components/Image'
import Link from 'components/Link'

const Logo = ({ ...props }) => {
	return (
		<Link href="/">
			<Image
				src='/logo.svg'
				alt='Atty'
				priority
				width="255"
				height="201"
				{...props}
			/>
		</Link>
	)
}

export default Logo
