import React from 'react'

import Link from './Link'

const Menu = () => (
	<>
		<Link href="/" px={4} py={4}>
			Home
		</Link>
		<Link href="/pricing" px={4} py={4}>
			Pricing
		</Link>
		<Link href="/about" px={4} py={4}>
			About
		</Link>
		{(process.env.NEXT_PUBLIC_DOMAIN === 'attyshop.xyz') && (
			<Link href={`https://admin.${process.env.NEXT_PUBLIC_DOMAIN}/`} variant="button">
				My Account
			</Link>
		)}
	</>
)

export default Menu
