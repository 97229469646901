import React from 'react'

import { VStack } from '@chakra-ui/react'

import Footer from 'components/Footer'
import Header from 'components/Header'


const Layout = ({ children }) => (
	<VStack gap={{ base: 24, lg: 40 }} alignItems="normal">
		<Header />
		{children}
		<Footer />
	</VStack>
)

export default Layout
